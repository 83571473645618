<template>
    <div class="services-page">
      <h1>{{ $t("services.services") }}</h1>
      <div class="services-grid">
        <!-- Service 1 -->
        <div class="service-square">
          <h2>{{ $t("services.info1_desc") }}</h2>
          <p>{{ $t("services.info1") }}</p>
        </div>
        
        <!-- Service 2 -->
        <div class="service-square">
          <h2>{{ $t("services.info2_desc") }}</h2>
          <p>{{ $t("services.info2") }}</p>
        </div>
        
        <!-- Service 3 -->
        <div class="service-square">
          <h2>{{ $t("services.info3_desc") }}</h2>
          <p>{{ $t("services.info3") }}</p>
        </div>
      </div>

      <div class="services-grid">
        <!-- Service 1 -->
        <div class="service-square">
          <h2>{{ $t("services.info4_desc") }}</h2>
          <p>{{ $t("services.info4") }}</p>
        </div>
        
        <!-- Service 2 -->
        <div class="service-square">
          <h2>{{ $t("services.info5_desc") }}</h2>
          <p>{{ $t("services.info5") }}</p>
        </div>
        
        <!-- Service 3 -->
        <div class="service-square">
          <h2>{{ $t("services.info6_desc") }}</h2>
          <p>{{ $t("services.info6") }}</p>
        </div>
      </div>

      <div class="services-grid">
        <!-- Service 1 -->
        <div class="service-square">
          <h2>{{ $t("services.info7_desc") }}</h2>
          <p>{{ $t("services.info7") }}</p>
        </div>
        
        <!-- Service 2 -->
        <div class="service-square">
          <h2>{{ $t("services.info8_desc") }}</h2>
          <p>{{ $t("services.info8") }}</p>
        </div>
        
        <!-- Service 3 -->
        <div class="service-square">
          <h2>{{ $t("services.info9_desc") }}</h2>
          <p>{{ $t("services.info9") }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { setMetaTags } from '@/utils/metaTags';

  export default {
    name: 'ServicesView',
    mounted() {   
    setMetaTags({
      title: "Aker Consultores",
      description: "Pagina donde se ofrecen y explican todos los servicios ofrecidos por la gestoria y sus profesionales. Ambitos fiscales, legales y financieros, entre otros.",
      keywords: [
        "aker consultores sl",
        "aker",
        "consultores",
        "gestoria",
        "contabilidad",
        "soluciones",
        "profesionales",
        "gestion",
        "negocio",
        "contacto",
        "home",
        "services",
        "contact",
        "introduction",
        "business",
        "web development"
      ],
      // image: "/path-to-your-image.jpg",
      });
    },
  };
  </script>
  
  <style src="@/assets/css/views/ServicesView.css"></style>
  