<template>
  <div class="dropdown">
    <!-- Button that displays the selected language flag -->
    <div class="dropdown-trigger" @click="toggleDropdown">
      <img :src="languageFlags[selectedLanguage]" :alt="selectedLanguage" class="flag-icon" />
    </div>

    <!-- Dropdown menu with flag options -->
    <div v-if="dropdownOpen" class="dropdown-menu">
      <div
        v-for="(flag, code) in languageFlags"
        :key="code"
        @click="selectLanguage(code)"
        class="dropdown-item"
      >
        <img :src="flag" :alt="code" class="flag-icon" />
      </div>
    </div>
  </div>
</template>

<script>
import language from "../lang/language";

// Explicitly import each flag SVG
import esFlag from "../assets/flags/es.svg";
import enFlag from "../assets/flags/en.svg";
import frFlag from "../assets/flags/fr.svg";
import deFlag from "../assets/flags/de.svg";

export default {
  name: "LanguageSwitcher",
  data() {
    return {
      dropdownOpen: false,
      selectedLanguage: 'es',
      languageFlags: {
        es: esFlag,
        en: enFlag,
        fr: frFlag,
        de: deFlag,
      }
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    selectLanguage(code) {
      this.selectedLanguage = code;
      this.dropdownOpen = false;
      language.switchLanguage(code);
    }
  },
  inject: ['$t'],
};
</script>