<template>
  <div class="about-container">
    <section id="about" class="about-section">
      <h2>{{ $t("about.about") }}</h2>
      <p>{{ $t("about.info") }}</p>
    </section>
    <img src="@/assets/imgs/aker-consultores-about.jpg" alt="About Image" class="about-image" />
  </div>

  <div class="about-container">
    <img src="@/assets/imgs/aker-consultores-about2.jpg" alt="About Image Sec" class="about-image" />
    <section id="about2" class="about-section">
      <h2>{{ $t("about.about2") }}</h2>
      <p>{{ $t("about.info2") }}</p>
      <p>{{ $t("about.info3") }}</p>
    </section>
  </div>
</template>

<script>
import { setMetaTags } from '@/utils/metaTags';

export default {
  name: 'AboutView',
  mounted() {   
    setMetaTags({
      title: "Aker Consultores",
      description: "Pagina explicativa de la web, donde se explica la trayectoria profesional de la gestoria.",
      keywords: [
        "aker consultores sl",
        "aker",
        "consultores",
        "gestoria",
        "contabilidad",
        "soluciones",
        "profesionales",
        "gestion",
        "negocio",
        "contacto",
        "sobre nosotros",
        "conocer",
        "home",
        "services",
        "contact",
        "introduction",
        "business",
        "web development"
      ],
      // image: "/path-to-your-image.jpg",
    });
  },
};
</script>

<style scoped>
.about-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  gap: 2rem;
  background-color: var(--background-color);
  color: var(--primary-text-color);
  border-radius: 8px;
}

.about-section {
  flex: 1;
  text-align: left;
  background-color: var(--background-color-header);
  color: var(--primary-text-color-header);
  border-radius: 8px;
  padding: 1rem;
}

.about-image {
  max-width: 800px;
  height: auto;
  border-radius: 8px;
}

.about-image-sec {
  max-width: 800px;
  height: auto;
  border-radius: 8px;
}

.about-section p {
  color: var(--secondary-text-color);
  line-height: 1.5;

  text-align: justify;
}

.about-container h2 {
    color: var(--primary-text-color-header);
  }
</style>
