// metaTags.js

export function setMetaTags({ title, description, keywords, image }) {
    // Set the document title
    if (title) {
      document.title = title;
    }
  
    // Set or create the meta description tag
    let metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', description);
    } else {
      metaDescription = document.createElement('meta');
      metaDescription.name = "description";
      metaDescription.content = description;
      document.head.appendChild(metaDescription);
    }
  
    // Handle multiple keywords - ensure it's a comma-separated string
    if (keywords) {
      const metaKeywords = document.querySelector('meta[name="keywords"]');
      const keywordsContent = Array.isArray(keywords) ? keywords.join(', ') : keywords;
      if (metaKeywords) {
        metaKeywords.setAttribute('content', keywordsContent);
      } else {
        const newMetaKeywords = document.createElement('meta');
        newMetaKeywords.name = "keywords";
        newMetaKeywords.content = keywordsContent;
        document.head.appendChild(newMetaKeywords);
      }
    }
  
    // Set or create Open Graph meta tags (for social sharing)
    if (image) {
      let ogImage = document.querySelector('meta[property="og:image"]');
      if (ogImage) {
        ogImage.setAttribute('content', image);
      } else {
        ogImage = document.createElement('meta');
        ogImage.property = "og:image";
        ogImage.content = image;
        document.head.appendChild(ogImage);
      }
    }
  
    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (ogTitle) {
      ogTitle.setAttribute('content', title);
    } else {
      ogTitle = document.createElement('meta');
      ogTitle.property = "og:title";
      ogTitle.content = title;
      document.head.appendChild(ogTitle);
    }
  
    let ogDescription = document.querySelector('meta[property="og:description"]');
    if (ogDescription) {
      ogDescription.setAttribute('content', description);
    } else {
      ogDescription = document.createElement('meta');
      ogDescription.property = "og:description";
      ogDescription.content = description;
      document.head.appendChild(ogDescription);
    }
  
    // Robots meta tag (optional, default is index, follow)
    let robots = document.querySelector('meta[name="robots"]');
    if (robots) {
      robots.setAttribute('content', 'index, follow');
    } else {
      robots = document.createElement('meta');
      robots.name = "robots";
      robots.content = 'index, follow';
      document.head.appendChild(robots);
    }
  }
  