<template>
  <footer class="footer">
    <p class="footer-text"><strong>&copy; 2024 Aker Consultores. All rights reserved.</strong></p>
    <div class="footer-links">
      <router-link to="/other" class="footer-link"><strong>{{ $t("otherinfo.otherinfo") }}</strong></router-link>
      <router-link to="/privacy" class="footer-link"><strong>{{ $t("footer.privacy") }}</strong></router-link>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style src="@/assets/css/components/AppFooter.css"></style>

