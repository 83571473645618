import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import AboutView from '@/views/AboutView.vue';
import ServicesView from '@/views/ServicesView.vue';
import ContactView from '@/views/ContactView.vue';
import PrivacyView from '@/views/PrivacyView.vue';
import OtherInfoView from '@/views/OtherInfoView.vue';

const routes = [
  { path: '/', name: 'Home', component: HomeView },
  { path: '/about', name: 'About', component: AboutView },
  { path: '/services', name: 'Services', component: ServicesView },
  { path: '/contact', name: 'Contact', component: ContactView },
  { path: '/privacy', name: 'Privacy', component: PrivacyView },
  { path: '/other', name: 'Other Information', component: OtherInfoView },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
