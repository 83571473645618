<template>
  <section id="privacy" class="privacy-section">
    <h2>{{ $t("privacy.privacy") }}</h2>
    
    <!-- Section 1 -->
    <h3>{{ $t("privacy.section1_title") }}</h3>
    <p>{{ $t("privacy.section1_paragraph") }}</p>

    <!-- Section 2 -->
    <p>{{ $t("privacy.section2_paragraph") }}</p>

    <!-- Section 3 -->
    <p>{{ $t("privacy.section3_paragraph") }}</p>

    <!-- Section 4 -->
    <p>{{ $t("privacy.section4_paragraph") }}</p>

    <!-- Section 5 -->
    <p>{{ $t("privacy.section5_paragraph") }}</p>

    <!-- Section 6 -->
    <p>{{ $t("privacy.section6_paragraph") }}</p>

    <!-- Purpose of Data Processing -->
    <h3>{{ $t("privacy.purpose_of_data_processing") }}</h3>
    <p>{{ $t("privacy.question1") }}</p>
    <p>{{ $t("privacy.purpose_paragraph") }}</p>
    <ul>
      <li>{{ $t("privacy.contract_purpose") }}</li>
      <li>{{ $t("privacy.info_request") }}</li>
      <li>{{ $t("privacy.newsletter") }}</li>
    </ul>
    <p>{{ $t("privacy.opt_out_info") }}</p>
    <p>{{ $t("privacy.mandatory_fields") }}</p>

    <!-- Data Retention -->
    <h3>{{ $t("privacy.data_retention") }}</h3>
    <p>{{ $t("privacy.data_retention_period") }}</p>

    <!-- Legitimation -->
    <h3>{{ $t("privacy.legitimation") }}</h3>
    <p>{{ $t("privacy.legitimation_paragraph") }}</p>
    <ul>
      <li>{{ $t("privacy.contract_terms") }}</li>
      <li>{{ $t("privacy.consent") }}</li>
    </ul>
    <p>{{ $t("privacy.data_provision_error") }}</p>

    <!-- Data Recipients -->
    <h3>{{ $t("privacy.data_recipients") }}</h3>
    <p>{{ $t("privacy.third_party_disclosure") }}</p>

    <!-- User Data Responsibility -->
    <h3>{{ $t("privacy.user_data_responsibility") }}</h3>
    <p>{{ $t("privacy.server_data_compliance") }}</p>

    <!-- Data Retention under LSSI -->
    <h3>{{ $t("privacy.data_retention_lssi") }}</h3>
    <p>{{ $t("privacy.lssi_retention") }}</p>
    <p>{{ $t("privacy.data_retention_legal") }}</p>
    <p>{{ $t("privacy.communication_with_forces") }}</p>

    <!-- Intellectual Property Rights -->
    <h3>{{ $t("privacy.intellectual_property_rights") }}</h3>
    <p>{{ $t("privacy.intellectual_property_paragraph") }}</p>
    <p>{{ $t("privacy.content_usage") }}</p>

    <!-- Software Intellectual Property -->
    <h3>{{ $t("privacy.software_intellectual_property") }}</h3>
    <p>{{ $t("privacy.software_rights") }}</p>
    <p>{{ $t("privacy.company_software_rights") }}</p>
    <p>{{ $t("privacy.service_software_rights") }}</p>
    <p>{{ $t("privacy.unauthorized_action") }}</p>

    <!-- Intellectual Property of Hosted Content -->
    <h3>{{ $t("privacy.intellectual_property_usage") }}</h3>
    <p>{{ $t("privacy.prohibited_usage") }}</p>
    <ul>
      <li>{{ $t("privacy.prohibited_actions1") }}</li>
      <li>{{ $t("privacy.prohibited_actions2") }}</li>
      <li>{{ $t("privacy.prohibited_actions3") }}</li>
      <li>{{ $t("privacy.prohibited_actions4") }}</li>
      <li>{{ $t("privacy.email_abuse") }}</li>
    </ul>
    <p>{{ $t("privacy.user_responsibility") }}</p>
    <p>{{ $t("privacy.user_indemnity") }}</p>

    <!-- Data Protection -->
    <h3>{{ $t("privacy.data_protection") }}</h3>
    <p>{{ $t("privacy.data_backup") }}</p>
    <p>{{ $t("privacy.backup_service") }}</p>
    <p>{{ $t("privacy.data_recovery") }}</p>

    <!-- Commercial Communications -->
    <h3>{{ $t("privacy.commercial_communications") }}</h3>
    <p>{{ $t("privacy.lssi_compliance") }}</p>
    <p>{{ $t("privacy.contractual_commercial") }}</p>
    <p>{{ $t("privacy.unsubscribe_option") }}</p>
  </section>
</template>

<script>
import { setMetaTags } from '@/utils/metaTags';

export default {
  name: 'PrivacyView',
  mounted() {   
    setMetaTags({
      title: "Aker Consultores",
      description: "Pagina sobre la politica de privacidad, contiene todo lo relacionado con la regulaciones de la privacidad y uso de datos.",
      keywords: [
        "aker consultores sl",
        "aker",
        "consultores",
        "gestoria",
        "contabilidad",
        "soluciones",
        "profesionales",
        "gestion",
        "negocio",
        "contacto",
        "home",
        "services",
        "contact",
        "introduction",
        "business",
        "web development"
      ],
      // image: "/path-to-your-image.jpg",
    });
  },
};
</script>

<style src="@/assets/css/views/PrivacyView.css"></style>
