import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import language from './lang/language';

const app = createApp(App);

// Add the $t method globally to the app
app.config.globalProperties.$t = (key) => {
  return key.split('.').reduce((obj, i) => obj && obj[i], language.state.translations) || key;
};

app.use(router).mount('#app');
