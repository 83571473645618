<template>
  <header :class="['header', { scrolled: isScrolled }]">
    <div class="logo">
      <img
        :src="isScrolled ? require('@/assets/imgs/logo/aker-consultores-logo-small.jpg') : require('@/assets/imgs/logo/aker-consultores-logo-main.png')"
        alt="Logo"
        class="logo-image"
      />
      <div class="text-container">
        <strong v-if="!isScrolled" class="main-name">Aker Consultores</strong>
        <strong v-else class="main-name">Consultores</strong>
        <strong v-if="!isScrolled" class="about-text">{{ $t("header.header_text") }}</strong>
      </div>
    </div>
    <nav class="nav">
      <router-link to="/"><strong>{{ $t("header.home") }}</strong></router-link>
      <router-link to="/about"><strong>{{ $t("header.about") }}</strong></router-link>
      <router-link to="/services"><strong>{{ $t("header.services") }}</strong></router-link>
      <router-link to="/contact"><strong>{{ $t("header.contact") }}</strong></router-link>
      <ThemeSwitcher />
      <LanguageSwitcher />
    </nav>
  </header>
</template>

<script>
import ThemeSwitcher from "./ThemeSwitcher.vue";
import LanguageSwitcher from "./LanguageSwitcher.vue";

export default {
  name: 'AppHeader',
  components: {
    ThemeSwitcher,
    LanguageSwitcher,
  },
  data() {
    return {
      isScrolled: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 1;
    },
  },
};
</script>

<style src="@/assets/css/components/AppHeader.css"></style>
