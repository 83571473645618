import { reactive } from 'vue';
import es from "./es";
import en from "./en";
import fr from "./fr";
import de from "./de";

const languages = {
  es,
  en,
  fr,
  de,
};

const state = reactive({
  currentLanguage: 'es',
  translations: languages.es,
});

function switchLanguage(lang) {
  if (languages[lang]) {
    state.currentLanguage = lang;
    state.translations = languages[lang];
  }
}

export default {
  state,
  switchLanguage,
};
