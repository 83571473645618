// revealObfuscated.js

export function getObfuscatedEmail() {
    const user = "info";
    const domain = "akerconsultores";
    const tld = "com";
    return `${user}@${domain}.${tld}`;
  }

export function getObfuscatedPhone() {
    const dom = "+34";
    const third1 = "96";
    const third2 = "126";
    const third3 = "50";
    const third4 = "25";
    const sp = " ";
    return `${dom}${sp}${third1}${sp}${third2}${sp}${third3}${sp}${third4}`;
  }
