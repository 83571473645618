<template>
    <button @click="toggleTheme" class="theme-toggle-btn">
      {{ theme === 'light' ? '⚫' : '⚪' }} <!-- Sun for light theme, Moon for dark theme '🌙' : '🌞' -->
    </button>
  </template>
  
  <script>
  export default {
    name: "ThemeSwitcher",
    data() {
      return {
        theme: localStorage.getItem("theme") || "light", // Default to light theme
      };
    },
    watch: {
      theme(newTheme) {
        document.body.className = newTheme;
        localStorage.setItem("theme", newTheme);
      },
    },
    methods: {
      toggleTheme() {
        this.theme = this.theme === "light" ? "dark" : "light";
      },
    },
    mounted() {
      document.body.className = this.theme;
    },
  };
  </script>
  
  <style scoped>
  .theme-toggle-btn {
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    color: inherit;
    padding: 0px 10px 5px;
    border-radius: 4px;
    transition: background-color 0.2s ease-in-out;
  }
  </style>
  